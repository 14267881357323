/* eslint-disable react/jsx-key */
import React from 'react'
import Wolflayout from '../../components/wolflayout'
import InfosHeader from '../../components/Infos/InfosHeader'
import InfosNavigation from '../../components/Infos/InfosNavigation'
import FestifHelmet from '../../components/Helmet/FestifHelmet'
import { allScenes } from '../../data/les-scenes'
import { DynamicStaticImage } from '../../components/ArtistAtSchool/dynamicStaticImage'

const Scenes = () => {
  const metaTitle = 'Les scènes | Le Festif! de Baie-Saint-Paul'
  const metaKeywords = 'festival, musique, évènement, spectacles, québec, charlevoix, scène'

  return (
    <Wolflayout mobileTitle={'Infos'}>
      <FestifHelmet title={metaTitle} keywords={metaKeywords} />
      <InfosNavigation />
      <InfosHeader title="Les scènes" />
      <div className="container content pb-12">

        <section className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 lg:!mt-[2rem]">

            { allScenes?.map((scene) => {
              let link = '/scenes/' + scene.id
              link = scene.id === 'parvis' ? '/infos/parvisdufestif#info_content' : link
              link = scene.id === 'spectacles-imprevisibles-loto-quebec' ? '/infos/imprevisibles#info_content' : link
              link = scene.id === 'rue-festive' ? '/infos/ruefestive#info_content' : link
              return (
                <a href={link} className="relative text-center z-0">

                  {/* SCENE'S NAME */}
                  <div className="absolute w-full bottom-0 z-30 mb-0 h-16 px-0 py-auto flex justify-center items-center rounded-b-2xl bg-purple-wolf2024dark">
                    {/* -1px because it is not centered according to Arno. It's probably the font */}
                    <p className={`!font-ohno !px-1 text-white !leading-none uppercase !${scene.name.length >= 45 ? 'text-sm' : 'text-lg'}`}>{scene.name}</p>
                  </div>

                  {/* SCENE'S IMAGE */}
                  <div className="relative overflow-hidden rounded-2xl">
                    <DynamicStaticImage localPath={scene.getImageLocalPath} alt={scene.name} className={'duration-300 scale-100 hover:scale-105'}/>
                  </div>
                </a>
              )
            })}

        </section>

      </div>
    </Wolflayout>
  )
}

export default Scenes
