/* eslint-disable quotes */

class CustomLink {
  constructor (title, link) {
    this.title = title
    this.link = link
  }
}

class SceneModel {
  constructor (id,
    name,
    description,
    videoUrl,
    htmlList,
    customLink = undefined,
    hideTicketButton = false) {
    this.id = id
    this.name = name
    this.description = description
    this.videoUrl = videoUrl
    this.htmlList = htmlList
    this.customLink = customLink
    this.hideTicketButton = hideTicketButton
    this.getImageLocalPath = `scenes/${this.id}.jpg`
    this.getBannerImageLocalPath = `scenes/${this.id}_banner.jpg`
  }
}

const allScenes =
  [
    new SceneModel(
      'place-desjardins',
      'Place Desjardins',
      'C’est notre première scène et la plus grosse du Festif! de Baie-Saint-Paul ! Au fil des ans, la Place Desjardins est devenue la chouchou des citoyen.nes. Elle a accueilli les plus gros noms du festival et continue d’épater par la qualité de ses performances. Laisse-toi surprendre par les Shows d’Escaliers et l’animation spontanée entre certains spectacles !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdgeUMTzAJOCiiM_place-desjardins.mp4',
      [
        '<p>Type d’accès : Passeport La Totale, Passeport Desjardins, billet unitaire, gratuité pour les 10 ans et moins</p>',
        '<p>Aucune place assise</p>',
        '<p>Zone à mobilité réduite accessible, merci de réserver à <a href="mailto:info@lefestif.ca">info@lefestif.ca</a></p>',
        '<p>Service de bar et de nourriture. Consulte le <a className="!underline" href="/infos/menu">menu</a>!</p>'
      ]
    ),
    new SceneModel(
      'scene-loto-quebec',
      'Scène Loto-Québec',
      'Qui ne rêve pas d’un spectacle d’après-midi sous les arbres enchanteurs du Parc de la Virevolte ? La mythique Scène Loto-Québec te charmera avec sa scénographie idyllique et son ambiance chaleureuse. Située entre la Rivière du Gouffre et le fameux terrain de pétanque de Baie-Saint-Paul, la Scène Loto-Québec accueille les performances qui redonneront du kick à ta journée !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdgOUMTzAJOCiiI_parc-de-la-virevolte-loto-quebec.mp4',
      [
        '<p>Type d’accès : Passeport La Totale, billet unitaire, gratuité pour les 10 ans et moins</p>',
        '<p>Places assises disponibles</p>',
        '<p>Service de bar</p>'
      ]
    ),
    new SceneModel(
      'quai-bell',
      'Quai Bell',
      'Commence ta journée du bon pied en te laissant émerveiller par la vue du fleuve et la bonne musique. Selon plusieurs, le Quai Bell est LA scène idéale pour admirer la beauté de Baie-Saint-Paul. Depuis la 6e édition du Festif!, elle charme les festivalier.ères avec ses prestations envoûtantes. Tu peux t’y rendre à pied en appréciant les spectacles En route vers le Quai Bell qui mettent en lumière des spectacles spontanés aux saveurs folkloriques, ou encore en empruntant la navette gratuite depuis le stationnement de la boutique Arts & Mer Gens.',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdgo0MTzAJOCiiN_quai-bell.mp4',
      [
        '<p>Scène gratuite, aucun billet nécessaire</p>',
        '<p>Places assises disponibles</p>',
        '<p>Service de bar</p>',
        '<p>Service de bar et de nourriture. Consulte le <a className="!underline" href="/infos/menu#info_content">menu</a></p>',
        '<p><a href="/infos/transports#info_content" className="!underline">Navette gratuite</a> disponible du stationnement d’Arts & Mer Gens</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'scene-microbrasserie-charlevoix',
      'Scène MicroBrasserie Charlevoix',
      'Lâche ton fou à la Scène MicroBrasserie Charlevoix ! Que ce soit en plein milieu de l’après-midi sous le soleil ou en formule 6@8 avec ta gang, c’est le lieu de prédilection pour montrer tes meilleurs mouvements de danse. Prépare-toi à voir tes bands favoris ou encore à découvrir ton prochain coup de cœur, directement sur la rue principale de Baie-Saint-Paul.',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdhGkMTzAJOCiiS_scene-microbrasserie-charlevoix.mp4',
      [
        '<p>Scène gratuite, aucun billet nécessaire</p>',
        '<p>Places assises disponibles</p>',
        '<p>Service de bar</p>',
        '<p>Aucune place assise</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'scene-siriusxm',
      'Scène SiriusXM',
      'Les oiseaux de nuit adorent la Scène SiriusXm ! Située en plein cœur du festival, juste à côté du Parvis, tu y trouveras les meilleurs spectacles de musique actuelle. Ce chapiteau à la scénographie éclectique fascine les festivalier.ères depuis la 5e édition. Rassemble ta gang et rejoins le party à la Scène SiriusXm !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdfpUMTzAJOCiiB_chapiteau-siriusxm.mp4',
      [
        '<p>Type d’accès : Passeport La Totale, billet unitaire, gratuité pour les 10 ans et moins </p>',
        '<p>Aucune place assise</p>',
        '<p>Service de bar</p>'
      ]
    ),
    new SceneModel(
      'scene-hydro-quebec',
      'Scène Hydro-Québec',
      'Certain.es diront que le hub de hip-hop de Baie-Saint-Paul se trouve à la Scène Hydro-Québec. À seulement quelques pas du Parvis, viens bouncer au rythme de tes rappeurs.euses préféré.es quand le soleil se couche. Intensité et moments mémorables sont les mots d’ordre de la scène Hydro-Québec !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdfk0MTzAJOCiiA_chapiteau-hydro-quebec.mp4',
      [
        '<p>Type d’accès : Passeport La Totale, billet unitaire, gratuité pour les 10 ans et moins</p>',
        '<p>Aucune place assise</p>',
        '<p>Service de bar</p>'
      ]
    ),
    new SceneModel(
      'garage-du-cure',
      'Garage du curé Exclaim!',
      'Fais le plein de découvertes d’ici et d’ailleurs à la scène du Garage du curé Exclaim! ! Reconnu pour ses spectacles qui brassent et ses artistes mémorables, le Garage du curé Exclaim! te permettra de finir ta soirée avec intensité. Quoi de mieux que d’apprécier un show bouillonnant au pied de l’église ?',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdf1UMTzAJOCiiE_garage-du-cure-exclaim.mp4',
      [
        '<p>Scène gratuite, aucun billet nécessaire</p>',
        '<p>Aucune place assise</p>',
        '<p>Service de bar</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'le-pit-a-sable',
      'Pit à sable Hydro-Québec en collaboration avec Solotech',
      'Le nom le dit, on fait des shows dans un Pit à sable ! Laisse-toi emporter dans le décor post-apocalyptique de notre scène la plus épique du festival. De la navette en bus jaune qui part du centre-ville jusqu’au milieu de la foule au plus profond du Pit, tu vivras certainement une expérience unique !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdf6UMTzAJOCiiF_le-pit-a-sable-solotech-en-collaboration-avec-aim.mp4',
      [
        '<p>Type d’accès : Passeport La Totale, billet unitaire, gratuité pour les 10 ans et moins</p>',
        '<p>Aucune place assise</p>',
        '<p>Service de bar</p>',
        '<p>Navette obligatoire, consulte la <a href="/infos/transports#info_content" className="!underline hover:!no-underline">section transport</a></p>'
      ]
    ),
    new SceneModel(
      'cour-a-joanne',
      'Cour à Joanne Blundstone',
      'L’accueillante Joanne t’ouvre la porte de sa cour arrière pour te laisser savourer les spectacles d’après-midi les plus doux. La Cour à Joanne héberge des spectacles qui font du bien et qui permettent une bouffée d’air dans une fin de semaine bien remplie. Prends place confortablement sous les grands érables et profite du calme de la Cour à Joanne.',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdfu0MTzAJOCiiD_cour-a-joanne-la-fabrique-culturelle.mp4',
      [
        '<p>Type d’accès : Passeport La Totale, billet unitaire, gratuité pour les 10 ans et moins</p>',
        '<p>Places assises disponibles</p>',
        '<p>Service de bar</p>'
      ]
    ),
    new SceneModel(
      'bus-festif',
      'Bus Festif! Radio-Canada ',
      'Le party bus de tes rêves arrive à Baie-Saint-Paul ! Le Bus Festif! Radio-Canada, c’est probablement le show le plus intime que tu vivras de ta vie. Viens te déchainer à proximité d’un.e artiste électrisant.e pendant que le Bus Festif! Radio-Canada se promène dans la ville. Une expérience inusitée bien à l’image du festival !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdgDEMTzAJOCiiG_bus-festif-radio-canada-billet-sur-place.mp4',
      [
        '<p>Type d’accès : billet unitaire obligatoire. Le Passeport La Totale ne donne pas accès à ces spectacles.</p>',
        '<p>18 ans et plus</p>',
        '<p>Aucune place assise</p>'
      ]
    ),
    new SceneModel(
      'concert-a-laube',
      'Concert à l’aube en collaboration avec Hôtel & Spa Le Germain Charlevoix',
      'Le Concert à l’aube t’envoûtera grâce à son décor pittoresque et son ambiance unique. Regarde le soleil se lever derrière les montagnes et les animaux de la ferme se réveiller tranquillement alors que tu es confortablement installé.e dans un énorme beanbag. On se charge de l’ambiance musicale (qui est bien paisible). Toi, es-tu plus du type lève-tôt ou couche-tard ?',
      undefined,
      [
        '<p>Type d’accès : Passeport La Totale , billet unitaire, gratuité pour les 10 ans et moins</p>',
        '<p>Places assises disponibles</p>'
      ]
    ),
    new SceneModel(
      'basse-cour',
      'La Basse-Cour des Viandes Bio de Charlevoix ',
      'Es-tu affamé.e de musique underground ? La Basse-Cour te fournit en découvertes musicales avec cette scène cachée de la rue principale. Profite de ton après-midi pour te ressourcer à l’ombre et laisse-toi tanguer au rythme de ton prochain coup de foudre musical !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdff0MTzAJOCih__les-off-festif.mp4',
      [
        '<p>Scène gratuite, aucun billet nécessaire</p>',
        '<p>Places assises dans le gazon, selon l’ambiance du spectacle</p>',
        '<p>Service de bar</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'serie-urbania',
      'Série URBANIA',
      'Des spectacles dans des lieux inusités, ça nous connait ! Mais les shows de la Série URBANIA repoussent les limites de l’imaginaire. On te réserve quatre spectacles dans quatre endroits différents, complètement funky et adaptés à la vibe de l’artiste qui performe. Le party pogne chaque fois que tu débarqueras à un spectacle de la Série URBANIA !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdhLkMTzAJOCiiU_serie-urbania.mp4',
      [
        '<p>Scène gratuite, places limitées</p>',
        '<p>Aucune place assise</p>',
        '<p>Salon de quilles charlevoix</p>',
        '<p>Bétonnière Béton Provincial</p>',
        '<p>Mousse Café</p>',
        '<p>Caserne de Pompier</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'experience-d-ecoute',
      'Les Expériences d’écoute',
      "Les expériences d’écoute te permettent de découvrir en primeur le nouvel album de ton artiste préféré dans un décor pensé sur mesure qui s’accorde parfaitement avec la vibe musicale. Un espèce de in situ de la musique où ton écoute est l'œuvre d’art. Abandonne-toi à ces chansons toutes fraîches et profite de ce moment bien confortable !",
      undefined,
      [
        '<p>Type d’accès : billet unitaire obligatoire. Le Passeport La Totale ne donne pas accès à ces spectacles.</p>',
        '<p>Informations à venir</p>',
        '<p>Billet unitaire obligatoire (même pour les enfants de 10 ans et moins)</p>'
      ]
    ),
    new SceneModel(
      'les-jams-de-l-arriere-pays',
      'Les jams de l’arrière pays',
      'Envie de te projeter dans le Charlevoix d’antan ? Plein feux sur les porteurs et porteuses de traditions de la région ! Ces musiciens et musiciennes perpétuent les chansons du répertoire folklorique lors de grands jam réunissant une trentaine d’artistes. On t’invite également à l’exposition du projet photos rendant hommage aux gardiens et gardiennes de traditions charlevoisiennes.',
      undefined,
      [
        '<p>Série gratuite, places limitées</p>',
        '<p>Exposition photos</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'espace-relax-promutuel',
      'Espace relax Promutuel',
      "C’est l’heure de te vider la tête à l’Espace relax Promutuel. Abandonne-toi à cet endroit apaisant qui t’offre une expérience unique, un oasis dans ta fin de semaine occupée. Les concerts offerts à l’Espace relax Promutuel te permettent de faire une relaxation bien méritée en t'emportant dans l’univers de l'instrumental et du contemplatif.",
      undefined,
      [
        '<p>Accès gratuit</p>',
        '<p>Espace de repos</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'pop-up-bouffe',
      'Pop up bouffe',
      'Un petite fringale ? Les Pop up Bouffe surgissent un peu partout dans la ville à n’importe quelle heure de la journée pour le plus grand plaisir des épicurien.nes et des affamé.es. En mode brunch, buffet ou munchies, les restaurateurs et restauratrices invité.es te boucheront certainement un coin. Surveille bien l’application du Festif! pour découvrir l’endroit et l’heure du prochain Pop up Bouffe. Bon appétit !',
      undefined,
      [
        '<p>Active tes notifications !</p>',
        '<p>Nourriture gratuite en quantité limitée</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'spectacles-imprevisibles-loto-quebec',
      'Spectacles Imprévisibles Loto-Québec',
      'Les surprises au Festif!, on connaît ça ! Nos mythiques Spectacles Imprévisibles Loto-Québec sont la cerise sur le sundae de ta journée. Reste à l’affût de leurs annonces via l’application du Festif! en activant les notifications et sois parmi les quelques un.es qui ont la chance d’y assister. Pour les victimes de FOMO, les Spectacles Imprévisibles Loto-Québec, c’est le rush ultime.',
      undefined,
      [
        '<p>Active tes notifications !</p>',
        '<p>Gratuit, places limitées</p>'
      ],
      new CustomLink("Plus d'infos", "/infos/imprevisibles#info_content"),
      true
    ),
    new SceneModel(
      'parvis',
      'Parvis Ici Musique',
      'C’est ici que se trouve le cœur du Festif! C’est le lieu de rendez-vous pour rejoindre ta gang, pour se ravitailler, pour prendre un break ou pour avoir recours à des services: bref, bienvenue au Parvis Ici Musique, l’épicentre du festival !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/ZjdgVUMTzAJOCiiJ_parvis.mp4',
      [
      ],
      new CustomLink("Plus d'infos", "/infos/parvis#info_content"),
      true
    ),
    new SceneModel(
      'les-off-festif',
      'Les Off Festif!',
      'Découvre des artistes en marge de la programmation officielle avec les Off Festif! Des spectacles 100% gratuits dans des endroits à découvrir de la ville de Baie-Saint-Paul.',
      undefined,
      [
        '<p>Série gratuite, places limitées</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'rue-festive',
      'Rue Festive Télé-Québec',
      'La Rue Festive Télé-Québec rend hommage à la Fête Foraine de Baie-Saint-Paul, qui a vu le jour dans les années 80 et qui s’est ensuite métamorphosée pour devenir l’emblématique Cirque du Soleil. Acrobat.es, jongleur.euses, fanfares et musicien.nes ambulant.es prennent d’assaut la rue Saint-Jean-Baptiste, en plein cœur du Festif!. Profite de la dizaine d’artistes et de troupes venu.es des quatre coins de la planète en famille ou entre ami.es ! On te donne rendez-vous dans cette ambiance déjantée et familiale !',
      'https://le-festif-ca.cdn.prismic.io/le-festif-ca/Zjdgt0MTzAJOCiiO_rue-festive.mp4',
      [
        '<p>Accès gratuit</p>',
        '<p>Aucune place assise</p>',
        '<p>Service de bar et de nourriture</p>'
      ],
      new CustomLink("Plus d'infos", "/infos/ruefestive#info_content"),
      true
    ),
    new SceneModel(
      'domaine-en-fugue',
      'Domaine en Fugue',
      'Une dizaine de musicien.nes à cordes s’unissent pour t’offrir des prestations musicales uniques et riches en émotions. Prenant place dans des décors apaisants, les spectacles du Domaine en Fugue te rempliront le cœur de musique et te laisseront un souvenir inoubliable.',
      undefined,
      [
        '<p>Série gratuite, places limitées</p>',
        '<p>Aucune place assise</p>'
      ],
      undefined,
      true
    ),
    new SceneModel(
      'hub-desjardins',
      'Hub Desjardins',
      'Tu te sens inspiré.e par la musique de la fin de semaine ? Viens créer la trame musicale de ton festival au Hub Desjardins ! Profite d’une expérience immersive et engageante où laisser aller ta créativité, grâce au Studio Desjardins et au Piano Urbain situés sur la rue Saint-Baptiste. C’est à ton tour de t’exprimer à travers la musique !',
      undefined,
      [
        '<p>Accès gratuit</p>',
        '<p>Jeudi 18 juillet : 17h à 20h</p>',
        '<p>Vendredi 19 juillet et samedi 20 juillet : 12h à 20h</p>',
        '<p>Boissons disponibles. Consulte le <a className="!underline" href="/infos/menu#info_content">menu</a></p>'
      ],
      undefined,
      true
    )
  ]

function getSceneById (id) {
  for (let i = 0; i < allScenes.length; i++) {
    if (allScenes[i].id === id) {
      return allScenes[i]
    }
  }

  return undefined
}

module.exports = {
  getSceneById,
  allScenes
}
