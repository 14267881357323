import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export const DynamicStaticImage = ({ localPath, alt, className }) => {
  const data = useStaticQuery(graphql`
      query {
        allFile {
          nodes {
            relativePath
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH)
            }
          }
        }
      }
    `)

  const imageNode = data.allFile.nodes.find(node => node.relativePath === localPath)
  if (!imageNode) {
    return <></>
  }

  const image = getImage(imageNode)
  return <GatsbyImage image={image} alt={alt || ''} className={className} />
}
