import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Banner from '../NewTheme/Ui/Banner'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { ParallaxProvider, useParallax } from 'react-scroll-parallax'

const getData = () => {
  const data = useStaticQuery(graphql`
    query {
        faqImage: file(relativePath: { eq: "infos/faq.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        transportImage: file(relativePath: { eq: "infos/transport.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        hebergementImage: file(relativePath: { eq: "infos/hebergement.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        reglementsImage: file(relativePath: { eq: "infos/reglements.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        appmobileImage: file(relativePath: { eq: "infos/appmobile.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        carteImage: file(relativePath: { eq: "infos/carte.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        parvisImage: file(relativePath: { eq: "infos/parvis.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        ruefestiveImage: file(relativePath: { eq: "infos/ruefestive.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        zoneDeServicesImage: file(relativePath: { eq: "infos/zonedeservices.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        imprevisiblesImage: file(relativePath: { eq: "infos/imprevisibles.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        familleImage: file(relativePath: { eq: "infos/famille.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        zonedereconfortImage: file(relativePath: { eq: "infos/zonedereconfort.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        scenesImage: file(relativePath: { eq: "infos/scenes.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
        menuImage: file(relativePath: { eq: "infos/menu.png" }) { childImageSharp { gatsbyImageData( placeholder: NONE ) } }
    }
  `)
  return data
}

const InfosNavigation = () => {
  const data = getData()
  const faqImage = getImage(data.faqImage)
  const transportImage = getImage(data.transportImage)
  const hebergementImage = getImage(data.hebergementImage)
  const reglementsImage = getImage(data.reglementsImage)
  const appmobileImage = getImage(data.appmobileImage)
  const carteImage = getImage(data.carteImage)
  const parvisImage = getImage(data.parvisImage)
  const ruefestiveImage = getImage(data.ruefestiveImage)
  const zoneDeServicesImage = getImage(data.zoneDeServicesImage)
  const imprevisiblesImage = getImage(data.imprevisiblesImage)
  const familleImage = getImage(data.familleImage)
  const zonedereconfortImage = getImage(data.zonedereconfortImage)
  const scenesImage = getImage(data.scenesImage)
  const menuImage = getImage(data.menuImage)

  const NavigationComponent = () => {
    const { ref: parallaxRef } = useParallax({
      speed: -4
    })

    return (
        <nav ref={parallaxRef} className={'pb-12'}>
            <ul className={'flex justify-center items-center flex-wrap max-w-[1200px] mx-auto'}>
                { NavigationButton('Faq', '/infos/faq#info_content', faqImage, 'Logo Faq') }
                { NavigationButton('Transport', '/infos/transports#info_content', transportImage, 'Logo Transport') }
                { NavigationButton('Hébergement', '/infos/hebergements#info_content', hebergementImage, 'Logo Hébergement') }
                { NavigationButton('Règlements', '/infos/reglements#info_content', reglementsImage, 'Logo Règlements') }
                { NavigationButton('App mobile', '/infos/appmobile#info_content', appmobileImage, 'Logo App mobile') }
                { NavigationButton('Carte', '/infos/carte#info_content', carteImage, 'Logo Carte') }
                { NavigationButton('Parvis', '/infos/parvisdufestif#info_content', parvisImage, 'Logo Parvis') }
                { NavigationButton('Rue Festive Télé-Québec', '/infos/ruefestive#info_content', ruefestiveImage, 'Logo Rue Festive') }
                { NavigationButton('Zone de services', '/infos/zonedeservices#info_content', zoneDeServicesImage, 'Logo Zone de services') }
                { NavigationButton('Spectacles Imprévisibles Loto-Québec', '/infos/imprevisibles#info_content', imprevisiblesImage, 'Logo Spectacles Imprévisibles Loto-Québec', true) }
                { NavigationButton('Famille', '/infos/famille#info_content', familleImage, 'Logo Famille') }
                { NavigationButton('Zone de réconfort', '/infos/zonedereconfort#info_content', zonedereconfortImage, 'Logo Zone de réconfort') }
                { NavigationButton('Les Scènes', '/infos/scenes#info_content', scenesImage, 'Logo Scènes') }
                { NavigationButton('Menu', '/infos/menu#info_content', menuImage, 'Logo Menu') }
            </ul>
        </nav>
    )
  }
  return (
        <div>
            <Banner title="Infos" />
            <ParallaxProvider>
                <NavigationComponent />
            </ParallaxProvider>
        </div>
  )
}

let colorIndex = 0
const NavigationButton = (title, internalLink, imageSrc, imageAlt, longText = false) => {
  const getNextColor = () => {
    const bgArray = ['bg-yellow-wolf2024', 'bg-blue-wolf2024', 'bg-red-wolf2024', 'bg-green-wolf2024']
    return bgArray[(colorIndex++) % bgArray.length]
  }

  return (
          <li className={`w-[150px] h-[150px] rounded-3xl m-[10px] ${getNextColor()} hover:bg-white-opacity20 transform transition-transform hover:scale-105`}>
              <a rel="noopener noreferrer" href={internalLink}>
                  <div className="m-[15px] !text-center !align-center">
                      <div>
                          <GatsbyImage image={imageSrc} alt={imageAlt} className={`w-[80px] ${longText ? 'h-[70px]' : 'h-[80px]'}`} imgStyle={{ objectFit: 'contain' }}/>
                      </div>
                      <div className={`font-dinnext text-xl uppercase text-purple-wolf2024dark inline-block relative leading-5 ${longText ? 'h-[54px]' : 'h-[24px] mt-[5px]'}`}>
                          {title}
                      </div>
                  </div>
              </a>
          </li>
  )
}

const FamilleButton = () => {
  const data = getData()
  return (
    <>
      { NavigationButton('Famille', '/infos/famille#info_content', getImage(data.familleImage), 'Logo Famille') }
    </>
  )
}
const MenuButton = () => {
  const data = getData()
  return (
    <>
      { NavigationButton('Menu', '/infos/menu#info_content', getImage(data.menuImage), 'Logo Menu') }
    </>
  )
}

export default InfosNavigation
export { FamilleButton, MenuButton }
