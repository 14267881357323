import React from 'react'

const Banner = ({ title, titleLine2 }) => {
  return (
    <header className="header lg:px-20 py-6 leading-none hidden md:block relative mb-16">
      <h1 className={`${titleLine2 ? '' : 'mb-4'}`}>{title}</h1>
      <h1 className={`${titleLine2 ? 'mb-4' : 'hidden'}`}>{titleLine2}</h1>
    </header>
  )
}

export default Banner
